/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// Styles
import 'styles/ServicesDetail.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'

const StyledBackgroundImage = styled(BackgroundImage)`
  @media (max-width: 991px) {
    p, strong {
      font-size: 18px !important;
    }
  }
`

const ServiceForRetailers = ({ data, pageContext }) => {
  const {
    wordpressPage: {
      title,
      acf: {
        title_section: titleSection,
        banner,
        content_section: contentSection,
      },
    },
  } = data

  function addGradient(imageProp) {
    const gradient = [
      imageProp,
      `linear-gradient(to left, rgba(0, 53, 120, 0) 32%, #001843)`,
    ].reverse()

    return gradient
  }

  return (
    <Layout extraFooterPiece>
      <SEO title={title} />
      <div className="service">
        <section className="empty-space-50 color-background-main d-flex align-items-center">
          <div className="container">
            <p className="text-white font-family-main font-size-xm mb-0">{parse(title)}</p>
          </div>
        </section>

        <section className="title mb-5">
          <div className="container mt-2">
            <div className="mb-5">
              <Breadcrumb crumbSeparator={' > '} crumbs={pageContext.breadcrumb.crumbs} crumbLabel={parse(title)} />
            </div>

            <div className="row">
              <div className="col-lg-9">
                <div className="d-flex align-items-center mb-4">
                  <h1 className="mb-0 font-weight-xl font-size-h1">{titleSection.title}</h1>
                </div>
                <Content className="pb-5" content={titleSection.description} />
              </div>
            </div>
          </div>
        </section>

        <StyledBackgroundImage
          fluid={addGradient(banner.image.localFile.childImageSharp.fluid)}
          className="background-banner mb-5"
          tag="section"
        >
          <div className="container py-5">
            <Content
              className="nested w-100 w-lg-40 py-lg-5 my-3 font-weight-xl color-text-light font-size-h1 line-height-s"
              content={banner.text}
            />
          </div>
        </StyledBackgroundImage>
        <div className="container mb-5">
          <div className="row justify-content-evenly mb-5">
            {contentSection.block.map((block, index) => (
              <div className="col-12 col-lg-4 my-3 my-lg-0 d-flex justify-content-center">
                <Block key={index} block={block} className="" />
              </div>
            ))}
          </div>
        </div>
        <div className="empty-space-100 mb-5" />
      </div>
    </Layout>
  )
}

export default ServiceForRetailers

const Block = ({ block: { title, description }, className }) => (
  <div className={`voor-retailers-block ${className ? `${className}` : ``}`}>
    <h2 className="font-size-ms font-weight-xl block-title color-background-contrast color-text-light text-center pt-4 pb-4 mb-4">{title}</h2>
    <p className="font-size-sm block-paragraph text-justify px-4">{parse(description)}</p>
  </div>
)

export const pageQuery = graphql`
	query ServiceForRetailersQuery($wordpress_id: Int!) {
		wordpressPage(wordpress_id: { eq: $wordpress_id }) {
			id
			wordpress_id
			title
			slug
			acf {
				title_section {
					title
					description
				}
				banner {
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 1920) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					text
				}
				content_section {
					block {
						title
						description
					}
				}
			}
		}
	}
`